import './RangeDatePicker.less';
import './ShiftDateForm.css';
import type { DateRange } from 'behavior/shift/types';
import type { DateRange as Dates } from 'rsuite/esm/DateRangePicker';
import { DateRangePicker } from 'rsuite';

type Props = {
  onInputChange: (range?: DateRange) => void;
  onCancelClick?: () => void;
  defaultValue?: DateRange;
  showCloseButton?: boolean;
};

const ShiftDateForm = ({ onCancelClick, onInputChange, defaultValue, showCloseButton = false }: Props) => {
  const onChange = (values: Dates | null) => {
    const paylaod = values ? {
      startDate: values[0],
      endDate: values[1],
    } : undefined;

    onInputChange(paylaod);
  };

  const parseDateRange = (range?: DateRange): Dates | null =>
    range ? [range.startDate, range.endDate] : null;

  return (
    <div className="shift-add-form">
      <DateRangePicker
        onChange={onChange}
        style={{ width: '14.8rem' }}
        showOneCalendar
        hoverRange="week"
        isoWeek
        format="dd/MM/yyyy"
        size="sm"
        character=" - "
        placeholder="dd/mm/yyyy - dd/mm/yyyy"
        ranges={[]}
        value={parseDateRange(defaultValue)}
      />
      {showCloseButton &&
        <div className="action-buttons">
          <button
            className="icon-button"
            onClick={onCancelClick}
          >
            <img
              className="button-img"
              src={require('assets/round_cancel.png')}
              alt="cancel shift"
            />
          </button>
        </div>}
    </div>
  );
};

export default ShiftDateForm;
