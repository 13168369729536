import './PeoplePerShift.css';
import type { RootState } from 'store/store';
import { useActions } from 'hooks/useActions';
import { useEffect, useMemo, useRef, useState, type KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { focusInputRef, setButtonStylingDisabled } from 'utils/domHandlers';
import ShiftSettingForm from '../forms/shiftSettingForm/ShiftSettingForm';

const PeoplePerShift = () => {
  const [peoplePerShiftValue, setPeoplePerShiftValue] = useState(0);
  const [isEditMode, setEditMode] = useState(false);

  const peoplePerShiftInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const currentPeoplePerShiftValue = useSelector((state: RootState) => state.shift.peoplePerShift);
  const { setPeoplePerShift, getPeoplePerShift } = useActions();

  useEffect(() => {
    getPeoplePerShift();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPeoplePerShiftValue(currentPeoplePerShiftValue);
  }, [currentPeoplePerShiftValue]);

  const isPeoplePerShiftValueValid = useMemo(() => {
    const isValid = peoplePerShiftValue >= 1;
    submitButtonRef.current && setButtonStylingDisabled(submitButtonRef, !isValid);

    return isValid;
  }, [peoplePerShiftValue]);

  const onChangePeoplePerShiftButtonClick = () => {
    setEditMode(true);
    focusInputRef(peoplePerShiftInputRef);
  };

  const onSetPeoplePerShiftButtonClick = () => {
    if (isPeoplePerShiftValueValid) {
      setPeoplePerShift(peoplePerShiftValue);
      setEditMode(false);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) =>
    event.key === 'Enter' && onSetPeoplePerShiftButtonClick();

  return (
    <div className="people-per-shift-container">
      <span className="people-per-shift-span">
        People per shift:
      </span>
      {isEditMode ? (
        <ShiftSettingForm
          inputRef={peoplePerShiftInputRef}
          submitButtonRef={submitButtonRef}
          defaultValue={currentPeoplePerShiftValue}
          onChange={e => setPeoplePerShiftValue(Number(e.target.value))}
          onKeyDown={onKeyDown}
          isSubmitButtonDisabled={!isPeoplePerShiftValueValid}
          onSubmitButtonClick={onSetPeoplePerShiftButtonClick}
        />
      ) : (
        <button className="button-link" onClick={onChangePeoplePerShiftButtonClick}>
          {currentPeoplePerShiftValue}
        </button>
      )}
    </div>
  );
};

export default PeoplePerShift;