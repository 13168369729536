import type { Person } from 'behavior/person';
import type { MouseEvent } from 'react';

type Props = {
  person: Person;
  onAddPerson: (event: MouseEvent<HTMLButtonElement>) => void;
};

const SelectorItem = ({ person: { id, name, team }, onAddPerson }: Props) => {
  return (
    <li key={id} value={id}>
      <button
        value={id}
        onClick={onAddPerson}
      >
        {name} - {team}
      </button>
    </li>
  );
};

export default SelectorItem;