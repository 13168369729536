export const addPersonMutation =
`mutation add($input:PersonAddInput){
  person{
    add(input:$input){
      id
      name
      team
    }
  }
}`;

export const getPersonListQuery =
`query getList{
  person{
    getList{
      id
      name
      team
    }
  }
}`;

export const deletePersonMutation =
`mutation delete($id:String!){
  person{
    delete(id:$id)
  }
}`;

export const editPersonMutation =
`mutation edit($input:PersonInput){
  person{
    edit(input:$input){
      id
      name
      team
    }
  }
}`;