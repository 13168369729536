import type { RefObject } from 'react';

type Props = {
  shouldRenderShiftEditCancelButton: boolean;
  shiftEditDateButtonRef: RefObject<HTMLButtonElement>;
  isEditButtonDisabled: boolean;
  onShiftDateCancel: () => void;
  onEditShiftClick: () => void;
  onDeleteShiftClick: () => void;
};

const ShiftActionButtonsTableCell = ({
    shouldRenderShiftEditCancelButton,
    shiftEditDateButtonRef,
    isEditButtonDisabled,
    onEditShiftClick,
    onShiftDateCancel,
    onDeleteShiftClick,
  }: Props) => (
    <td>
      {shouldRenderShiftEditCancelButton ? (
        <div className="action-btns-container">
          <button onClick={onShiftDateCancel} className="icon-button cancel-btn">
            <img src={require('assets/round_cancel.png')} alt="Cancel" />
          </button>
        </div>
      ) : (
        <div className="action-btns-container">
          <button
            className="icon-button edit-btn"
            onClick={onEditShiftClick}
            ref={shiftEditDateButtonRef}
            disabled={isEditButtonDisabled}
          >
            <img
              src={require('assets/edit.png')}
              alt="Edit shift date"
            />
          </button>
          <button className="icon-button delete-btn" onClick={onDeleteShiftClick}>
            <img
              src={require('assets/delete.png')}
              alt="Delete shift"
            />
          </button>
        </div>
      )}
    </td>
);

export default ShiftActionButtonsTableCell;
