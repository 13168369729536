import type { StoreDependencies } from './types';
import { createEpicMiddleware  } from 'redux-observable';
import { applyMiddleware, createStore, type Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import rootEpic from './rootEpic';

const configureStore = (dependencies: StoreDependencies) => {
  const epicMiddleware = createEpicMiddleware<Action, Action, RootState, StoreDependencies>({ dependencies });

  const middlewareEnchancer = applyMiddleware(epicMiddleware);
  const composedEnchancers = composeWithDevTools(middlewareEnchancer);

  const store = createStore(
    rootReducer,
    composedEnchancers,
  );

  epicMiddleware.run(rootEpic);

  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export default configureStore;