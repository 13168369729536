import './PersonRow.css';
import type { Person } from 'behavior/person/types';
import { type RefObject, type Dispatch, type SetStateAction, useRef } from 'react';
import { useActions } from 'hooks/useActions';
import { focusInputRef } from 'utils/domHandlers';
import PersonEditFormRow from '../forms/PersonEditFormRow';

type Props = {
  person: Person;
  nameInputRef: RefObject<HTMLInputElement>;
  currentEditRecordKey?: string;
  setCurrentEditRecordKey: Dispatch<SetStateAction<undefined | string>>;
};

const PersonRow = ({ person: { id, name, team }, nameInputRef, currentEditRecordKey, setCurrentEditRecordKey }: Props) => {
  const nameEditInputRef = useRef<HTMLInputElement>(null);

  const { deletePerson } = useActions();

  const onEditClick = () => {
    setCurrentEditRecordKey(id);
    focusInputRef(nameEditInputRef);
  };

  const onDeleteClick = () => {
    deletePerson(id);
    focusInputRef(nameInputRef);
  };

  return id === currentEditRecordKey ? (
    <PersonEditFormRow
      initialValues={{ id, name, team }}
      nameInputRef={nameInputRef}
      nameEditInputRef={nameEditInputRef}
      setCurrentEditRecordKey={setCurrentEditRecordKey}
    />
    ) : (
    <tr className="person-row">
      <td className="name-table-data">
        {name}
      </td>
      <td className="team-table-data">
        {team}
      </td>
      <td className="actions-table-data">
        <div className="action-btns-container">
          <button
            className="icon-button"
            onClick={onEditClick}
          >
            <img src={require('assets/edit.png')} alt="edit" />
          </button>
          <button
            className="icon-button"
            onClick={onDeleteClick}
          >
            <img src={require('assets/delete.png')} alt="delete" />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default PersonRow;