import './PersonTable.css';
import type { RootState } from 'store/store';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'hooks/useActions';
import { sortPersonList } from 'utils/dataHandlers';
import PersonRow from '../personRow/PersonRow';
import PersonAddFormRow from '../forms/PersonAddFormRow';

const PersonTable = () => {
  const [currentEditRecordKey, setCurrentEditRecordKey] = useState<undefined | string>(undefined);

  const nameInputRef = useRef<HTMLInputElement>(null);
  const { getPersonList } = useActions();

  useEffect(() => {
    nameInputRef.current?.focus();
  });

  useEffect(() => {
    getPersonList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const personList = useSelector((state: RootState) => state.person);
  const sortedPersonList = sortPersonList(personList);

  return (
    <div>
      <div className="person-header">
        <h2>People</h2>
      </div>
      <table className="person-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Team</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <PersonAddFormRow
            nameInputRef={nameInputRef}
            isAddInputsDisabled={!!currentEditRecordKey}
          />
          {sortedPersonList.map(person => (
            <PersonRow
              key={person.id}
              person={person}
              currentEditRecordKey={currentEditRecordKey}
              setCurrentEditRecordKey={setCurrentEditRecordKey}
              nameInputRef={nameInputRef}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PersonTable;