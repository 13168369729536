import './ShiftsHeader.css';
import ShiftDuration from '../duration/ShiftDuration';
import PeoplePerShift from '../peoplePerShift/PeoplePerShift';

const ShiftsHeader = () => {
  return (
    <div className="shifts-header-wrapper">
      <div className="shifts-header">
        <h2>Shifts</h2>
        <PeoplePerShift />
        <ShiftDuration />
      </div>
    </div>
  );
};

export default ShiftsHeader;