import type {
  ShiftAddResponse,
  ShiftGetAllResponse,
  ShiftDurationGetCurrentResponse,
  ShiftDurationSetResponse,
  PeoplePerShiftGetResponse,
  PeoplePerShiftSetResponse,
  ShiftRemovePersonResponse,
  ShiftDeleteResponse,
  ShiftEditDateResponse,
} from './types';
import type { Epic } from 'store/types';
import {
  type ShiftCommandAction,
  SHIFT_DURATION_GET_CURRENT,
  REMOVE_PERSON_FROM_SHIFT,
  PEOPLE_PER_SHIFT_SET,
  PEOPLE_PER_SHIFT_GET,
  SHIFT_DURATION_SET,
  SHIFT_GET_ALL,
  DELETE_SHIFT,
  SHIFT_ADD,
  personFromShiftRemoved,
  peoplePerShiftChanged,
  peoplePerShiftLoaded,
  shiftDurationChanged,
  shiftDurationLoaded,
  shiftsLoaded,
  shiftAdded,
  shiftDeleted,
  EDIT_SHIFT_DATE,
  shiftDateEdited,
} from './actions';
import {
  addShiftMutation,
  deleteShiftMutation,
  editShiftDateMutation,
  getAllShiftsQuery,
  getCurrentShiftDurationQuery,
  getPeoplePerShiftQuery,
  removePersonFromShiftMutation,
  setPeoplePrShiftMutation,
  setShiftDurationMutation,
} from './queries';
import { ofType } from 'redux-observable';
import { mergeMap, map, merge, switchMap } from 'rxjs';
import { parseShiftResponse } from 'utils/dataHandlers';

const shiftEpic: Epic<ShiftCommandAction> = (action$, _, { api }) => {
  const getCurrentShiftDuration$ = action$.pipe(
    ofType(SHIFT_DURATION_GET_CURRENT),
    switchMap(() => api.graphRequest<ShiftDurationGetCurrentResponse>(getCurrentShiftDurationQuery).pipe(
      map(response => response.shift.getDuration),
      map(shiftDurationLoaded),
    )),
  );

  const setShiftDuration$ = action$.pipe(
    ofType(SHIFT_DURATION_SET),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<ShiftDurationSetResponse>(setShiftDurationMutation, { duration: payload }).pipe(
      map(response => response.shift.setDuration),
      map(shiftDurationChanged),
    )),
  );

  const getPeoplePerShift$ = action$.pipe(
    ofType(PEOPLE_PER_SHIFT_GET),
    switchMap(() => api.graphRequest<PeoplePerShiftGetResponse>(getPeoplePerShiftQuery).pipe(
      map(response => response.shift.getPeoplePerShift),
      map(peoplePerShiftLoaded),
    )),
  );

  const setPeoplePerShift$ = action$.pipe(
    ofType(PEOPLE_PER_SHIFT_SET),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<PeoplePerShiftSetResponse>(setPeoplePrShiftMutation, { value: payload }).pipe(
      map(response => response.shift.setPeoplePerShift),
      map(peoplePerShiftChanged),
    )),
  );

  const getAllShifts$ = action$.pipe(
    ofType(SHIFT_GET_ALL),
    switchMap(() => api.graphRequest<ShiftGetAllResponse>(getAllShiftsQuery).pipe(
      map(response => response.shift.getAll),
      map(parseShiftResponse),
      map(shiftsLoaded),
    )),
  );

  const addShift$ = action$.pipe(
    ofType(SHIFT_ADD),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<ShiftAddResponse>(addShiftMutation, { input: payload }).pipe(
      map(response => [response.shift.add]),
      map(parseShiftResponse),
      map(shifts => shifts[0]),
      map(shiftAdded),
    )),
  );

  const removePersonFromShift$ = action$.pipe(
    ofType(REMOVE_PERSON_FROM_SHIFT),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<ShiftRemovePersonResponse>(removePersonFromShiftMutation, { personId: payload }).pipe(
      map(response => response.shift.removePerson),
      map(personFromShiftRemoved),
    )),
  );

  const deleteShift$ = action$.pipe(
    ofType(DELETE_SHIFT),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<ShiftDeleteResponse>(deleteShiftMutation, { groupId: payload }).pipe(
      map(response => response.shift.deleteShift),
      map(shiftDeleted),
    )),
  );

  const editShiftDate$ = action$.pipe(
    ofType(EDIT_SHIFT_DATE),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<ShiftEditDateResponse>(editShiftDateMutation, { input: payload }).pipe(
      map(response => response.shift.editShiftDate),
      map(({ startDate, endDate, ...rest }) => {
        return {
          ...rest,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        };
      }),
      map(shiftDateEdited),
    )),
  );

  return merge(
    getCurrentShiftDuration$,
    setShiftDuration$,
    getAllShifts$,
    addShift$,
    getPeoplePerShift$,
    setPeoplePerShift$,
    removePersonFromShift$,
    deleteShift$,
    editShiftDate$,
  );
};

export default shiftEpic;
