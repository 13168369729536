import type { ShiftState } from './types';
import {
  type ShiftDocumentAction,
  SHIFT_DURATION_LOADED,
  SHIFT_DURATION_CHANGED,
  SHIFTS_LOADED,
  SHIFT_ADDED,
  PEOPLE_PER_SHIFT_LOADED,
  PEOPLE_PER_SHIFT_CHANGED,
  PERSON_FROM_SHIFT_REMOVED,
  SHIFT_DELETED,
  SHIFT_DATE_EDITED,
} from './actions';

export const initialState: ShiftState = {
  shiftDuration: 0,
  peoplePerShift: 0,
  shifts: [],
};

const reducer = (state: ShiftState = initialState, action: ShiftDocumentAction): ShiftState => {
  switch(action.type) {
    case SHIFTS_LOADED:
      return {
        ...state,
        shifts: action.payload,
      };
    case SHIFT_ADDED:
      return {
        ...state,
        shifts: [...state.shifts, action.payload],
      };
    case PEOPLE_PER_SHIFT_LOADED:
    case PEOPLE_PER_SHIFT_CHANGED:
      return {
        ...state,
        peoplePerShift: action.payload,
      };
    case SHIFT_DURATION_LOADED:
    case SHIFT_DURATION_CHANGED:
      return {
        ...state,
        shiftDuration: action.payload,
      };
    case PERSON_FROM_SHIFT_REMOVED:
      return {
        ...state,
        shifts: state.shifts.filter(s => s.personId !== action.payload),
      };
    case SHIFT_DELETED:
      return {
        ...state,
        shifts: state.shifts.filter(s => s.groupId !== action.payload),
      };
    case SHIFT_DATE_EDITED:
      const shifts = state.shifts.map(shift => {
        const { oldGroupId, groupId, startDate, endDate } = action.payload;
        if (shift.groupId === oldGroupId) {
          shift.groupId = groupId;
          shift.startDate = startDate;
          shift.endDate = endDate;
        }

        return shift;
      });

      return {
        ...state,
        shifts,
      };
    default:
      return state;
  }
};

export default reducer;