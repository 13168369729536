import type { PersonAddInput, Person, PersonEditInput } from './types';

export const PERSON_ADD = 'PERSON_ADD' as const;
export const addPerson = (person: PersonAddInput) => ({ type: PERSON_ADD, payload: person });

export const PERSON_ADDED = 'PERSON_ADDED' as const;
export const personAdded = (person: Person) => ({ type: PERSON_ADDED, payload: person });

export const PERSON_GET_LIST = 'PERSON_GET_LIST' as const;
export const getPersonList = () => ({ type: PERSON_GET_LIST });

export const PERSON_LOADED = 'PERSON_LOADED' as const;
export const personListLoaded = (person: Person[]) => ({ type: PERSON_LOADED, payload: person });

export const PERSON_DELETE = 'PERSON_DELETE' as const;
export const deletePerson = (id: string) => ({ type: PERSON_DELETE, payload: id });

export const PERSON_DELETED = 'PERSON_DELETED' as const;
export const personDeleted = (id: string) => ({ type: PERSON_DELETED, payload: id });

export const PERSON_EDIT = 'PERSON_EDIT' as const;
export const editPerson = (person: PersonEditInput) => ({ type:PERSON_EDIT, payload: person });

export const PERSON_EDITED = 'PERSON_EDITED' as const;
export const personEdited = (person: Person) => ({ type: PERSON_EDITED, payload: person });

export type PersonDocumentAction = ReturnType<
  | typeof personAdded
  | typeof personListLoaded
  | typeof personDeleted
  | typeof personEdited
>;

export type PersonCommandAction = ReturnType<
  | typeof addPerson
  | typeof getPersonList
  | typeof deletePerson
  | typeof editPerson
>;