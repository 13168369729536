export const getCurrentShiftDurationQuery =
`query duration{
  shift{
    getDuration
  }
}`;

export const setShiftDurationMutation =
`mutation set($duration:Int!){
  shift{
    setDuration(duration:$duration)
  }
}`;

export const getPeoplePerShiftQuery =
`query peoplePerShift{
  shift{
    getPeoplePerShift
  }
}`;

export const setPeoplePrShiftMutation =
`mutation setPeoplePerShift($value:Int!){
  shift{
    setPeoplePerShift(value:$value)
  }
}`;

export const getAllShiftsQuery =
`query getAll{
  shift{
    getAll{
      groupId
      startDate
      endDate
      person
      personId
    }
  }
}`;

export const addShiftMutation =
`mutation add($input:ShiftAddInput){
  shift{
    add(input:$input){
      groupId
      startDate
      endDate
      person
      personId
    }
  }
}`;

export const removePersonFromShiftMutation =
`mutation removePerson($personId:String!){
  shift{
    removePerson(personId:$personId)
  }
}`;

export const deleteShiftMutation =
`mutation removeShift($groupId:String!){
  shift{
    deleteShift(groupId:$groupId)
  }
}`;

export const editShiftDateMutation =
`mutation editShiftDate($input:ShiftEditDateInput){
  shift{
    editShiftDate(input:$input){
      oldGroupId
      groupId
      startDate
      endDate
    }
  }
}`;
