import './PersonRecordListItem.css';
import type { Dispatch, SetStateAction } from 'react';
import type { PersonRecord } from 'behavior/person';
import { useActions } from 'hooks/useActions';

type Props = {
  record: PersonRecord;
  setCurrentShiftRecordKey: Dispatch<SetStateAction<string | undefined>>;
};

const PersonRecordListItem = ({ record: { id, person }, setCurrentShiftRecordKey }: Props) => {
  const { removePersonFromShift } = useActions();

  const onClick = () => {
    removePersonFromShift(id);
    setCurrentShiftRecordKey(undefined);
  };

  return (
    <li className="person-list-item" key={id}>
      <div className="person">
        {person}
      </div>
      <div className="remove-btn-container">
        <button className="icon-button" onClick={onClick}>
          <img
            src={require('assets/round_cancel.png')}
            alt="Remove person from the shift"
          />
        </button>
      </div>
    </li>
  );
};

export default PersonRecordListItem;
