import './ShiftRow.css';
import type { DateRange, ShiftAddInput, ShiftDisplayTableData } from 'behavior/shift';
import type { Person } from 'behavior/person';
import {
  type SetStateAction,
  type Dispatch,
  type MouseEvent,
  type ComponentProps,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useActions } from 'hooks/useActions';
import { setButtonStylingDisabled } from 'utils/domHandlers';
import { toIsoString } from 'utils/dataHandlers';
import PersonRecordListItem from './personRecordRow/PersonRecordListItem';
import ShiftDateForm from '../forms/shiftDateForm/ShiftDateForm';
import PersonSelectorTableCell from './PersonSelectorTableCell';
import ShiftActionButtonsTableCell from './ShiftActionButtonsTableCell';

type BaseProps = {
  currentShiftRecordKey?: string;
  setCurrentShiftRecordKey: Dispatch<SetStateAction<string | undefined>>;
  disablePersonSelector: boolean;
  personList: Person[];
};

type DefinedShiftProps = {
  shift: ShiftDisplayTableData;
} & BaseProps;

type UnDefinedShiftProps = {
  shift: DateRange;
  assumedShiftGroupId: string;
  onUnDefinedShiftDelete: (index: number) => void;
  onUnDefinedShiftUpdate: (assumedShiftGroupId: string, startDate: Date, endDate: Date) => void;
} & BaseProps;

const isDefinedShift = (props: ComponentProps<typeof ShiftRow>): props is DefinedShiftProps => !('assumedShiftGroupId' in props);

const ShiftRow = (props: DefinedShiftProps | UnDefinedShiftProps) => {
  const {
    setCurrentShiftRecordKey,
    currentShiftRecordKey,
    disablePersonSelector,
    shift: {
      startDate,
      endDate,
    },
    personList,
  } = props;

  const isDefined = isDefinedShift(props);
  const groupId = isDefined ? props.shift.groupId : props.assumedShiftGroupId;

  const [isEditShifDateMode, setEditShiftDateMode] = useState(false);
  const [isAddPersonMode, setAddPersonMode] = useState(false);
  const [isPersonSelectorEmpty, setPersonSelectorEmpty] = useState(false);

  const { addShift, deleteShift, editShiftDate } = useActions();

  const personSelectorButtonRef = useRef<HTMLButtonElement>(null);
  const shiftEditDateButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    personSelectorButtonRef.current && setButtonStylingDisabled(personSelectorButtonRef, disablePersonSelector || isPersonSelectorEmpty);
    shiftEditDateButtonRef.current && setButtonStylingDisabled(shiftEditDateButtonRef, disablePersonSelector);
  }, [disablePersonSelector, isPersonSelectorEmpty]);

  const onAddPersonClick = (event: MouseEvent<HTMLButtonElement>) => {
    const index = personList.findIndex(person => person.id === event.currentTarget.value);

    const payload: ShiftAddInput = {
      startDate: toIsoString(startDate),
      endDate: toIsoString(endDate),
      person: personList[index],
    };

    personList[index] && addShift(payload);
    setAddPersonMode(false);
    setCurrentShiftRecordKey(undefined);
  };

  const onOpenPersonSelector = () => {
    setAddPersonMode(true);
    setEditShiftDateMode(false);
    setCurrentShiftRecordKey(groupId);
  };

  const onPersonSelectorCancel = () => {
    setAddPersonMode(false);
    setCurrentShiftRecordKey(undefined);
  };

  const onDeleteShiftClick = () => {
    if (isDefined) {
      deleteShift(groupId);
      setCurrentShiftRecordKey(undefined);
      return;
    }

    props.onUnDefinedShiftDelete(Number(groupId));
    setCurrentShiftRecordKey(undefined);
  };

  const onEditShiftClick = () => {
    setAddPersonMode(false);
    setEditShiftDateMode(true);
    setCurrentShiftRecordKey(groupId);

    setTimeout(() => {
      const datePickerInput: HTMLInputElement | null = document.querySelector('.shift-add-form .rs-picker-toggle-textbox');
      datePickerInput?.click();
    });
  };

  const onShiftDateChange = (range?: DateRange) => {
    if (!range)
      return;

    const { startDate, endDate } = range;
    if (isDefined) {
      editShiftDate({ groupId, startDate: toIsoString(startDate), endDate: toIsoString(endDate) });
    }
    else {
      const { assumedShiftGroupId, onUnDefinedShiftUpdate } = props;
      onUnDefinedShiftUpdate(assumedShiftGroupId, startDate, endDate);
    }

    setEditShiftDateMode(false);
    setCurrentShiftRecordKey(undefined);
  };

  const onShiftDateCancel = () => {
    setEditShiftDateMode(false);
    setCurrentShiftRecordKey(undefined);
  };

  const getFormatedDate = () => {
    const firstDate = `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`;
    const lastDate = `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`;

    return `${firstDate} - ${lastDate}`;
  };

  const shouldRenderDateForm = isEditShifDateMode && currentShiftRecordKey === groupId && !isAddPersonMode;
  const shouldRenderShiftEditCancelButton = isEditShifDateMode && currentShiftRecordKey === groupId;
  const shouldRenderPersonSelector = isAddPersonMode && currentShiftRecordKey === groupId && !isEditShifDateMode;

  const ShiftDateTableCell = shouldRenderDateForm ? (
    <td>
      <ShiftDateForm onInputChange={onShiftDateChange} defaultValue={{ startDate, endDate }} />
    </td>
  ) : (
    <td>{getFormatedDate()}</td>
  );

  const PersonListTableCell = (
    <td>
      <ul className="person-list">
        {isDefined && props.shift.people.map(person => (
          <PersonRecordListItem
            key={person.id}
            record={person}
            setCurrentShiftRecordKey={setCurrentShiftRecordKey}
          />
        ))}
      </ul>
    </td>
  );

  return (
    <>
      <tr className="shift-row">
        {ShiftDateTableCell}
        {PersonListTableCell}
        <ShiftActionButtonsTableCell
          shouldRenderShiftEditCancelButton={shouldRenderShiftEditCancelButton}
          shiftEditDateButtonRef={shiftEditDateButtonRef}
          isEditButtonDisabled={disablePersonSelector}
          onShiftDateCancel={onShiftDateCancel}
          onEditShiftClick={onEditShiftClick}
          onDeleteShiftClick={onDeleteShiftClick}
        />
      </tr>
      <tr className="shift-row">
        <PersonSelectorTableCell
          shouldRenderSelector={shouldRenderPersonSelector}
          personList={personList}
          personSelectorButtonRef={personSelectorButtonRef}
          isOpenSelectorButtonDisabled={disablePersonSelector || isPersonSelectorEmpty}
          onOpenPersonSelector={onOpenPersonSelector}
          onAddPerson={onAddPersonClick}
          onCancel={onPersonSelectorCancel}
          setPersonSelectorEmpty={setPersonSelectorEmpty}
          setAddPersonMode={setAddPersonMode}
          setCurrentShiftRecordKey={setCurrentShiftRecordKey}
        />
      </tr>
    </>
  );
};

export default ShiftRow;
