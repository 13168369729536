import './ShiftTable.css';
import type { DateRange } from 'behavior/shift';
import type { RootState } from 'store/store';
import { convertShiftsToDisplayTableData, setUndefinedShiftsCallback } from 'utils/dataHandlers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useActions } from 'hooks/useActions';
import { useSelector } from 'react-redux';
import { setButtonStylingDisabled } from 'utils/domHandlers';
import ShiftDateForm from '../forms/shiftDateForm/ShiftDateForm';
import ShiftRow from '../shiftRow/ShiftRow';

function equalityComparer<T>(firstState: T, secondState: T) {
  return JSON.stringify(firstState) === JSON.stringify(secondState);
}

const ShiftTable = () => {
  const [unDefinedShifts, setUnDefinedShifts] = useState<DateRange[]>([]);
  const [isAddShiftMode, setAddShiftMode] = useState(false);
  const [currentShiftRecordKey, setCurrentShiftRecordKey] = useState<string | undefined>(undefined);

  const { getAllShifts } = useActions();

  const loadedShifts = useSelector((state: RootState) => state.shift.shifts);
  const personList = useSelector((state: RootState) => state.person, equalityComparer);

  const shiftAddButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    getAllShifts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personList]);

  useEffect(() => {
    setUnDefinedShifts(state => setUndefinedShiftsCallback(state, loadedShifts));
  }, [loadedShifts]);

  useEffect(() => {
    setButtonStylingDisabled(shiftAddButtonRef, !!currentShiftRecordKey);
  }, [currentShiftRecordKey]);

  useEffect(() => {
    const datePickerInput: HTMLInputElement = document.querySelector('.rs-picker-toggle-textbox')!;
    datePickerInput?.click();
  }, [isAddShiftMode]);

  const shiftsDisplayData = useMemo(() => convertShiftsToDisplayTableData(loadedShifts), [loadedShifts]);

  const onAddClick = () => setAddShiftMode(true);
  const onCancelClick = () => setAddShiftMode(false);

  const onInputChange = (range?: DateRange) => {
    range && setUnDefinedShifts(state => [...state, { ...range }]);
    setAddShiftMode(false);
  };

  const onUndefinedShiftDelete = (index: number) => {
    setUnDefinedShifts(state => state.filter(shift => state.indexOf(shift) !== index));
  };

  const onUndefinedShiftUpdate = (assumedShiftId: string, startDate: Date, endDate: Date) => {
    setUnDefinedShifts(state => {
      const index = Number(assumedShiftId);
      const shift = state[index];

      shift.startDate = startDate;
      shift.endDate = endDate;

      const updatedState = [...state];
      updatedState.splice(index, 1);
      updatedState.splice(index, 0, shift);

      return updatedState;
    });
  };

  return (
    <div className="shifts-container">
      <table className="shift-table">
        <thead>
          <tr>
            <th>Date Range</th>
            <th>People</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {shiftsDisplayData
            .sort((a, b) => Number(a.startDate) - Number(b.startDate))
            .map((shift, index) => (
              <ShiftRow
                key={index}
                shift={shift}
                disablePersonSelector={isAddShiftMode}
                currentShiftRecordKey={currentShiftRecordKey}
                setCurrentShiftRecordKey={setCurrentShiftRecordKey}
                personList={personList}
              />
          ))}
          {unDefinedShifts
            .sort((a, b) => Number(a.startDate) - Number(b.startDate))
            .map((shift, index) => (
              <ShiftRow
                key={index}
                assumedShiftGroupId={index.toString()}
                shift={shift}
                disablePersonSelector={isAddShiftMode}
                onUnDefinedShiftDelete={onUndefinedShiftDelete}
                onUnDefinedShiftUpdate={onUndefinedShiftUpdate}
                currentShiftRecordKey={currentShiftRecordKey}
                setCurrentShiftRecordKey={setCurrentShiftRecordKey}
                personList={personList}
              />
          ))}
        </tbody>
      </table>
      <div className="shift-add-form-container">
        {isAddShiftMode ?
        (
          <ShiftDateForm
            onCancelClick={onCancelClick}
            onInputChange={onInputChange}
            showCloseButton
          />
        ) : (
          <button
            className="icon-button"
            onClick={onAddClick}
            disabled={!!currentShiftRecordKey}
            ref={shiftAddButtonRef}
          >
            <img
              className="button-img"
              src={require('assets/plus.png')}
              alt="add new shift"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default ShiftTable;
