import type { StoreDependencies } from './types';
import createApi from 'api/apiFactory';
import configureStore from './store';

export const api = createApi();

const dependencies: StoreDependencies = {
  api,
};

const store = configureStore(dependencies);
export default store;