import './HomePage.css';
import ShiftContainer from 'components/shift';
import PersonTable from 'components/person';

const HomePage = () => {
  return (
    <div className="main-container">
      <PersonTable />
      <ShiftContainer />
    </div>
  );
};

export default HomePage;