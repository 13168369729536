import {
  type KeyboardEvent,
  type ChangeEvent,
  type RefObject,
  useState,
  useEffect,
  useRef,
} from 'react';
import { setButtonStylingDisabled } from 'utils/domHandlers';
import { useActions } from 'hooks/useActions';
import PersonFormRow from './PersonFormRow';

type Props = {
  isAddInputsDisabled: boolean;
  nameInputRef: RefObject<HTMLInputElement>;
};

const PersonAddFormRow = ({ isAddInputsDisabled, nameInputRef }: Props) => {
  const [name, setName] = useState('');
  const [team, setTeam] = useState('');
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { addPerson } = useActions();

  useEffect(() => {
    const isInputValid = name.trim().length && team.trim().length;

    setSubmitButtonDisabled(!isInputValid || isAddInputsDisabled);
    setButtonStylingDisabled(submitButtonRef, !isInputValid || isAddInputsDisabled);
  }, [name, team, isAddInputsDisabled]);

  const handleAddButton = () => {
    addPerson({
      name: name.trim(),
      team: team.trim(),
    });

    setName('');
    setTeam('');
    nameInputRef.current?.focus();
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) =>
    event.key === 'Enter' && !isSubmitButtonDisabled && handleAddButton();

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value);
  const onTeamChange = (event: ChangeEvent<HTMLInputElement>) => setTeam(event.target.value);

  return (
    <PersonFormRow
      nameInputRef={nameInputRef}
      initialValues={{ name, team }}
      isInputsDisabled={isAddInputsDisabled}
      onInputKeyDown={onInputKeyDown}
      onNameChange={onNameChange}
      onTeamChange={onTeamChange}
    >
      <div className="submit-btn-table-data">
        <button
          className="add-btn"
          disabled={isSubmitButtonDisabled}
          onClick={handleAddButton}
          type="submit"
          ref={submitButtonRef}
        >
          <img src={require('assets/plus.png')} alt="add" />
        </button>
      </div>
    </PersonFormRow>
  );
};

export default PersonAddFormRow;