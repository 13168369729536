import './ShiftSettingForm.css';
import type { RefObject, KeyboardEvent, ChangeEvent } from 'react';

type Props = {
  defaultValue: number;
  inputRef: RefObject<HTMLInputElement>;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  submitButtonRef: RefObject<HTMLButtonElement>;
  onSubmitButtonClick: () => void;
  isSubmitButtonDisabled: boolean;
};

const ShiftSettingForm = (props: Props) => {
  const {
    defaultValue,
    inputRef,
    onKeyDown,
    onChange,
    onSubmitButtonClick,
    submitButtonRef,
    isSubmitButtonDisabled,
  } = props;

  return (
    <div className="shift-setting-form">
      <input
        ref={inputRef}
        className="shift-setting-input"
        type="number"
        name="setting-value"
        id="setting-value"
        defaultValue={defaultValue}
        min="1"
        step="1"
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <button
        className="submit-button icon-button"
        ref={submitButtonRef}
        onClick={onSubmitButtonClick}
        disabled={isSubmitButtonDisabled}
      >
        <img src={require('assets/checkmark.png')} alt="submit" />
      </button>
    </div>
  );
};

export default ShiftSettingForm;