import ShiftsHeader from '../header/ShiftsHeader';
import ShiftTable from '../tables/ShiftTable';

const Container = () => {
  return (
    <div>
      <ShiftsHeader />
      <ShiftTable />
    </div>
  );
};

export default Container;
