import type { RefObject } from 'react';

export const setButtonStylingDisabled = (ref: RefObject<HTMLButtonElement>, isButtonDisabled = true) => {
  if (isButtonDisabled) {
    ref.current!.classList.add('disabled-button');
    return;
  }

  ref.current!.classList.remove('disabled-button');
};

export const focusInputRef = (ref: RefObject<HTMLInputElement | HTMLSelectElement>) => {
  setTimeout(() => {
    ref.current?.focus();
  }, 1);
};
