import './PersonSelector.css';
import type { Person } from 'behavior/person';
import type { MouseEvent } from 'react';
import Item from './selectorItem/SelectorItem';

type Props = {
  personList: Person[];
  onAddPerson: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: () => void;
};

const PersonSelector = ({ personList, onAddPerson, onCancel }: Props) => (
  <div className="selector-container">
    <h3 className="selector-heading">Select person</h3>
    <div className="selector-wrapper">
      <ul className="person-selector">
        {personList
          .map(person => (
            <Item
              key={person.id}
              person={person}
              onAddPerson={onAddPerson}
            />
        ))}
      </ul>
      <div className="action-buttons">
          <button className="icon-button square-button" onClick={onCancel}>
            <img
              className="button-img"
              src={require('assets/cancel.png')}
              alt="Cancel adding person to shift"
            />
          </button>
        </div>
    </div>
  </div>
);

export default PersonSelector;
