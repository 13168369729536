import type { PersonState } from './types';
import {
  type PersonDocumentAction,
  PERSON_ADDED,
  PERSON_LOADED,
  PERSON_DELETED,
  PERSON_EDITED,
} from './actions';

export const initialState: PersonState = [];

const reducer = (state: PersonState = initialState, action: PersonDocumentAction): PersonState => {
  switch (action.type) {
    case PERSON_LOADED:
      return action.payload;
    case PERSON_ADDED:
      return [...state, action.payload];
    case PERSON_DELETED:
      return state.filter(person => person.id !== action.payload);
    case PERSON_EDITED:
      return [
        ...state.filter(person => person.id !== action.payload.id),
        action.payload,
      ];
    default:
      return state;
  }
};

export default reducer;