import type { StoreDependencies } from './types';
import type { Action } from 'redux';
import type { RootState } from './store';
import { combineEpics } from 'redux-observable';
import personEpic from 'behavior/person/epic';
import shiftEpic from 'behavior/shift/epic';

const rootEpic = combineEpics<Action, Action, RootState, StoreDependencies>(
  personEpic,
  shiftEpic,
);

export default rootEpic;