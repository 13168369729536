import type { Epic } from 'store/types';
import type {
  PersonAddResponse,
  PersonDeleteResponse,
  PersonEditResponse,
  PersonGetListResponse,
} from './types';
import {
  addPersonMutation,
  deletePersonMutation,
  editPersonMutation,
  getPersonListQuery,
 } from './queries';
 import {
  type PersonCommandAction,
  personListLoaded,
  personAdded,
  personDeleted,
  personEdited,
  PERSON_ADD,
  PERSON_GET_LIST,
  PERSON_DELETE,
  PERSON_EDIT,
} from './actions';
import { combineEpics, ofType } from 'redux-observable';
import { map, mergeMap, switchMap } from 'rxjs';

const addPersonEpic: Epic<PersonCommandAction> = (action$, _, { api }) => {
  return action$.pipe(
    ofType(PERSON_ADD),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<PersonAddResponse>(addPersonMutation, { input: payload }).pipe(
      map(response => response.person.add),
      map(personAdded),
    )),
  );
};

const getPersonListEpic: Epic<PersonCommandAction> = (action$, _, { api }) => {
  return action$.pipe(
    ofType(PERSON_GET_LIST),
    switchMap(() => api.graphRequest<PersonGetListResponse>(getPersonListQuery).pipe(
      map(response => response.person.getList),
      map(personListLoaded),
    )),
  );
};

const deletePersonEpic: Epic<PersonCommandAction> = (action$, _, { api }) => {
  return action$.pipe(
    ofType(PERSON_DELETE),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<PersonDeleteResponse>(deletePersonMutation, { id: payload }).pipe(
      map(response => response.person.delete),
      map(personDeleted),
    )),
  );
};

const editPersonEpic: Epic<PersonCommandAction> = (action$, _, { api }) => {
  return action$.pipe(
    ofType(PERSON_EDIT),
    map(action => action.payload),
    mergeMap(payload => api.graphRequest<PersonEditResponse>(editPersonMutation, { input: payload }).pipe(
      map(response => response.person.edit),
      map(personEdited),
    )),
  );
};

export default combineEpics(
  addPersonEpic,
  getPersonListEpic,
  deletePersonEpic,
  editPersonEpic,
);