import { exhaustMap, from, map } from 'rxjs';

const performGraphQLRequest = <T>(query: string, variables?: unknown) => {
  const API_URL = process.env.REACT_APP_API_URL!;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ query, variables }),
  };

  return from(fetch(API_URL, requestOptions)).pipe(
    exhaustMap(response => from(response.json()).pipe(
      map((json: ApiResponse<T>) => json.data),
    )),
  );
};

const createApi = () => ({
  graphRequest: performGraphQLRequest,
});

export type ApiResponse<T> = {
  data: T;
};

export type Api = ReturnType<typeof createApi>;
export default createApi;