import './ShiftDuration.css';
import type { RootState } from 'store/store';
import { useActions } from 'hooks/useActions';
import { useEffect, useRef, useState, useMemo, type KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { setButtonStylingDisabled, focusInputRef } from 'utils/domHandlers';
import ShiftSettingForm from '../forms/shiftSettingForm/ShiftSettingForm';

const ShiftDuration = () => {
  const [isEditMode, setEditMode] = useState(false);
  const [duration, setDuration] = useState(0);

  const durationInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const currentShiftDuration = useSelector((state: RootState) => state.shift.shiftDuration);
  const { getCurrentShiftDuration, setShiftDuration } = useActions();

  useEffect(() => {
    getCurrentShiftDuration();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDuration(currentShiftDuration);
  }, [currentShiftDuration]);

  const isDurationValid = useMemo(() => {
    const isValid = duration >= 1;
    submitButtonRef.current && setButtonStylingDisabled(submitButtonRef, !isValid);

    return isValid;
  }, [duration]);

  const onChangeDurationButtonClick = () => {
    setEditMode(true);
    focusInputRef(durationInputRef);
  };

  const onSetDurationButtonClick = () => {
    if (isDurationValid) {
      setShiftDuration(duration);
      setEditMode(false);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) =>
    event.key === 'Enter' && onSetDurationButtonClick();

  return (
    <div className="shift-duration-container">
      <span className="duration-span">
        Shift duration:
      </span>
      {isEditMode ? (
        <ShiftSettingForm
          inputRef={durationInputRef}
          submitButtonRef={submitButtonRef}
          defaultValue={currentShiftDuration}
          onChange={e => setDuration(Number(e.target.value))}
          onKeyDown={onKeyDown}
          isSubmitButtonDisabled={!isDurationValid}
          onSubmitButtonClick={onSetDurationButtonClick}
        />
      ) : (
        <button className="button-link" onClick={onChangeDurationButtonClick}>
          {currentShiftDuration} week(s)
        </button>
      )}
    </div>
  );
};

export default ShiftDuration;