import type { Shift, ShiftAddInput, ShiftEditDateInput, ShiftEditedData } from './types';

export const SHIFT_GET_ALL = 'SHIFT_GET_ALL' as const;
export const getAllShifts = () => ({ type: SHIFT_GET_ALL });

export const SHIFTS_LOADED = 'SHIFTS_LOADED' as const;
export const shiftsLoaded = (shifts: Shift[]) => ({ type: SHIFTS_LOADED, payload: shifts });

export const SHIFT_ADD = 'SHIFT_ADD' as const;
export const addShift = (shift: ShiftAddInput) => ({ type: SHIFT_ADD, payload: shift });

export const SHIFT_ADDED = 'SHIFT_ADDED' as const;
export const shiftAdded = (shift: Shift) => ({ type: SHIFT_ADDED, payload: shift });

export const SHIFT_DURATION_GET_CURRENT = 'SHIFT_DURATION_GET_CURRENT' as const;
export const getCurrentShiftDuration = () => ({ type: SHIFT_DURATION_GET_CURRENT });

export const SHIFT_DURATION_LOADED = 'SHIFT_DURATION_LOADED' as const;
export const shiftDurationLoaded = (duration: number) => ({ type: SHIFT_DURATION_LOADED, payload: duration });

export const SHIFT_DURATION_SET = 'SHIFT_DURATION_SET' as const;
export const setShiftDuration = (duration: number) => ({ type: SHIFT_DURATION_SET, payload: duration });

export const SHIFT_DURATION_CHANGED = 'SHIFT_DURATION_CHANGED' as const;
export const shiftDurationChanged = (duration: number) => ({ type: SHIFT_DURATION_CHANGED, payload: duration });

export const PEOPLE_PER_SHIFT_GET = 'PEOPLE_PER_SHIFT_GET' as const;
export const getPeoplePerShift = () => ({ type: PEOPLE_PER_SHIFT_GET });

export const PEOPLE_PER_SHIFT_LOADED = 'PEOPLE_PER_SHIFT_LOADED' as const;
export const peoplePerShiftLoaded = (value: number) => ({ type: PEOPLE_PER_SHIFT_LOADED, payload: value });

export const PEOPLE_PER_SHIFT_SET = 'PEOPLE_PER_SHIFT_SET' as const;
export const setPeoplePerShift = (value: number) => ({ type: PEOPLE_PER_SHIFT_SET, payload: value });

export const PEOPLE_PER_SHIFT_CHANGED = 'PEOPLE_PER_SHIFT_CHANGED' as const;
export const peoplePerShiftChanged = (value: number) => ({ type: PEOPLE_PER_SHIFT_CHANGED, payload: value });

export const REMOVE_PERSON_FROM_SHIFT = 'REMOVE_PERSON_FROM_SHIFT' as const;
export const removePersonFromShift = (personId: string) => ({ type: REMOVE_PERSON_FROM_SHIFT, payload: personId });

export const PERSON_FROM_SHIFT_REMOVED = 'PERSON_FROM_SHIFT_REMOVED' as const;
export const personFromShiftRemoved = (personId: string) => ({ type: PERSON_FROM_SHIFT_REMOVED, payload: personId });

export const DELETE_SHIFT = 'DELETE_SHIFT' as const;
export const deleteShift = (groupId: string) => ({ type: DELETE_SHIFT, payload: groupId });

export const SHIFT_DELETED = 'SHIFT_DELETED' as const;
export const shiftDeleted = (groupId: string) => ({ type: SHIFT_DELETED, payload: groupId });

export const EDIT_SHIFT_DATE = 'EDIT_SHIFT_DATE' as const;
export const editShiftDate = (input: ShiftEditDateInput) => ({ type: EDIT_SHIFT_DATE, payload: input });

export const SHIFT_DATE_EDITED = 'SHIFT_DATE_EDITED' as const;
export const shiftDateEdited = (data: ShiftEditedData) => ({ type: SHIFT_DATE_EDITED, payload: data });

export type ShiftCommandAction = ReturnType<
  | typeof getCurrentShiftDuration
  | typeof setShiftDuration
  | typeof getAllShifts
  | typeof addShift
  | typeof getPeoplePerShift
  | typeof setPeoplePerShift
  | typeof removePersonFromShift
  | typeof deleteShift
  | typeof editShiftDate
>;

export type ShiftDocumentAction = ReturnType<
  | typeof shiftDurationLoaded
  | typeof shiftDurationChanged
  | typeof shiftsLoaded
  | typeof shiftAdded
  | typeof peoplePerShiftLoaded
  | typeof peoplePerShiftChanged
  | typeof personFromShiftRemoved
  | typeof shiftDeleted
  | typeof shiftDateEdited
>;
