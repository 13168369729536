import {
  type Dispatch,
  type SetStateAction,
  type RefObject,
  type ChangeEvent,
  type KeyboardEvent,
  useEffect,
  useState,
  useRef,
} from 'react';
import { setButtonStylingDisabled, focusInputRef } from 'utils/domHandlers';
import { useActions } from 'hooks/useActions';
import PersonFormRow from './PersonFormRow';

type Props = {
  initialValues: {
    id: string;
    name: string;
    team: string;
  };
  nameInputRef: RefObject<HTMLInputElement>;
  nameEditInputRef: RefObject<HTMLInputElement>;
  setCurrentEditRecordKey: Dispatch<SetStateAction<undefined | string>>;
};

const PersonEditFormRow = (props: Props) => {
  const {
    initialValues: {
      id,
      name,
      team,
    },
    nameInputRef,
    nameEditInputRef,
    setCurrentEditRecordKey,
  } = props;

  const [newName, setNewName] = useState(name);
  const [newTeam, setNewTeam] = useState(team);
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { editPerson } = useActions();

  useEffect(() => {
    const isInputValid = newName.trim().length && newTeam.trim().length;

    setSubmitButtonDisabled(!isInputValid);
    setButtonStylingDisabled(submitButtonRef, !isInputValid);
  }, [newName, newTeam]);

  const setDefaultFieldValues = () => {
    setNewName(name);
    setNewTeam(team);
  };

  const handleEditClick = () => {
    editPerson({
      id,
      name: newName.trim(),
      team: newTeam.trim(),
    });
    setCurrentEditRecordKey(undefined);
    focusInputRef(nameInputRef);
  };

  const onCancelClick = () => {
    setDefaultFieldValues();
    setCurrentEditRecordKey(undefined);
    focusInputRef(nameInputRef);
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter':
        if (!isSubmitButtonDisabled) {
          handleEditClick();
        }
        break;
      case 'Escape':
        onCancelClick();
        break;
    }
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => setNewName(event.target.value);
  const onTeamChange = (event: ChangeEvent<HTMLInputElement>) => setNewTeam(event.target.value);

  return (
    <PersonFormRow
      initialValues={{
        name: newName,
        team: newTeam,
      }}
      onInputKeyDown={onInputKeyDown}
      onNameChange={onNameChange}
      onTeamChange={onTeamChange}
      nameInputRef={nameEditInputRef}
      isInputsDisabled={false}
    >
      <div className="action-btns-container">
        <button
          className="icon-button"
          type="submit"
          onClick={handleEditClick}
          disabled={isSubmitButtonDisabled}
          ref={submitButtonRef}
        >
          <img src={require('assets/checkmark.png')} alt="submit" />
        </button>
        <button
          className="icon-button"
          type="submit"
          onClick={onCancelClick}
        >
          <img src={require('assets/round_cancel.png')} alt="cancel" />
        </button>
      </div>
    </PersonFormRow>
  );
};

export default PersonEditFormRow;