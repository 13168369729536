import type { DateRange, Shift, ShiftDisplayTableData, ShiftResponse } from 'behavior/shift';
import type { Person, PersonRecord } from 'behavior/person';

const compareShiftsDate = (dateRange: DateRange, shift: Shift) => {
  return dateRange.startDate.toDateString() === shift.startDate.toDateString() &&
    dateRange.endDate.toDateString() === shift.endDate.toDateString();
};

export const setUndefinedShiftsCallback = (state: DateRange[], loadedShifts: Shift[]) => {
  return state.filter(range => !loadedShifts.some(shift => compareShiftsDate(range, shift)));
};

export const convertShiftsToDisplayTableData = (shifts: Shift[]): ShiftDisplayTableData[] => {
  const shiftDisplayTableData: ShiftDisplayTableData[] = [];

  if (shifts.length === 0)
    return shiftDisplayTableData;

  const uniqueShifts = [...new Map(shifts.map(item => [item['groupId'], item])).values()];

  for (const { groupId, startDate, endDate } of uniqueShifts) {
    const people: PersonRecord[] = shifts
      .filter(shift => shift.groupId === groupId)
      .map(({ personId, person }) => ({ id: personId!, person: person! }));

    const sortedRecords = sortPesronRecords(people);
    shiftDisplayTableData.push({ groupId: groupId!, startDate, endDate, people: sortedRecords });
  }

  return shiftDisplayTableData;
};

const sortPesronRecords = (records: PersonRecord[]) => {
  return records.sort((a, b) => {
    const firstPerson = getPersonData(a.person);
    const secondPerson = getPersonData(b.person);

    return personComparator(firstPerson, secondPerson);
  });
};

const getPersonData = (person: string) => {
  const index = person.indexOf('-');
  const name = person.substring(0, index - 1);
  const team = person.substring(index + 2, person.length);

  return { name, team };
};

export const toIsoString = (date: Date) => {
  const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  const month = date.getMonth() >= 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const year = date.getFullYear();

  return `${year}-${month}-${day}T00:00:00.000Z`;
};

export const parseShiftResponse = (shifts: ShiftResponse[]): Shift[] => {
  return shifts.map(({ startDate, endDate, ...rest }) =>
    ({
      ...rest,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    }));
};

export const sortPersonList = (personList: Person[]) =>
  personList.sort((a, b) => personComparator(a, b));

const personComparator = (firstPerson: Omit<Person, 'id'>, secondPerson: Omit<Person, 'id'>) =>
  firstPerson.team.localeCompare(secondPerson.team) || firstPerson.name.localeCompare(secondPerson.name);
