import type { RefObject, ChangeEvent, KeyboardEvent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  initialValues: {
    name: string;
    team: string;
  };
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onTeamChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onInputKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  nameInputRef: RefObject<HTMLInputElement>;
  isInputsDisabled: boolean;
};

const PersonFormRow = (props: Props) => {
  const {
    children,
    initialValues: {
      name,
      team,
    },
    onNameChange,
    onTeamChange,
    onInputKeyDown: handleOnKeyDown,
    nameInputRef,
    isInputsDisabled,
  } = props;

  return (
    <tr className="person-row">
      <td className="name-table-data">
        <input
          name="name"
          id="name"
          type="text"
          value={name}
          autoComplete="off"
          onChange={onNameChange}
          onKeyDown={handleOnKeyDown}
          ref={nameInputRef}
          disabled={isInputsDisabled}
        />
      </td>
      <td className="team-table-data">
        <input
          name="team"
          id="team"
          type="text"
          value={team}
          autoComplete="off"
          onChange={onTeamChange}
          onKeyDown={handleOnKeyDown}
          disabled={isInputsDisabled}
        />
      </td>
      <td className="actions-table-data">
        {children}
      </td>
    </tr>
  );
};

export default PersonFormRow;