import { type RootState } from 'store/store';
import { type Person } from 'behavior/person';
import {
  type MouseEvent,
  type SetStateAction,
  type Dispatch,
  type RefObject,
  useMemo,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { sortPersonList } from 'utils/dataHandlers';
import PersonSelector from 'components/person/selector/PersonSelector';

type Props = {
  shouldRenderSelector: boolean;
  personList: Person[];
  personSelectorButtonRef: RefObject<HTMLButtonElement>;
  isOpenSelectorButtonDisabled: boolean;
  onOpenPersonSelector: () => void;
  onAddPerson: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: () => void;
  setPersonSelectorEmpty: Dispatch<SetStateAction<boolean>>;
  setAddPersonMode: Dispatch<SetStateAction<boolean>>;
  setCurrentShiftRecordKey: Dispatch<SetStateAction<string | undefined>>;
};

const PersonSelectorTableCell = (props: Props) => {
  const {
    shouldRenderSelector,
    personList,
    personSelectorButtonRef,
    isOpenSelectorButtonDisabled,
    onCancel,
    onAddPerson,
    onOpenPersonSelector,
    setAddPersonMode,
    setPersonSelectorEmpty,
    setCurrentShiftRecordKey,
  } = props;

  const shifts = useSelector((state: RootState) => state.shift.shifts);

  const sortedPersonList = useMemo(() => {
    const filteredPersonList = personList.filter(person => !shifts.some(s => s.personId === person.id));
    return sortPersonList(filteredPersonList);
  }, [personList, shifts]);

  useEffect(() => {
    const isPersonListEmpty = sortedPersonList.length === 0;

    setPersonSelectorEmpty(isPersonListEmpty);
    if (isPersonListEmpty) {
      setCurrentShiftRecordKey(undefined);
      setAddPersonMode(false);
    }
  }, [sortedPersonList, setPersonSelectorEmpty, setAddPersonMode, setCurrentShiftRecordKey]);

  if (shouldRenderSelector) {
    return (
      <td>
        <div className="add-person-form">
          <PersonSelector
            personList={sortedPersonList}
            onAddPerson={onAddPerson}
            onCancel={onCancel}
          />
        </div>
      </td>
    );
  }

  return (
    <td>
      <button
        disabled={isOpenSelectorButtonDisabled}
        className="icon-button display-person-selector-btn"
        onClick={onOpenPersonSelector}
        ref={personSelectorButtonRef}
      >
        <img
          className="button-img"
          src={require('assets/contact.png')}
          alt="Display person selector"
        />
      </button>
    </td>
  );
};

export default PersonSelectorTableCell;
