import {
  addPerson,
  editPerson,
  deletePerson,
  getPersonList,
  personAdded,
  personEdited,
  personDeleted,
  personListLoaded,
} from 'behavior/person/actions';
import {
  getCurrentShiftDuration,
  setShiftDuration,
  getAllShifts,
  addShift,
  getPeoplePerShift,
  setPeoplePerShift,
  peoplePerShiftChanged,
  peoplePerShiftLoaded,
  removePersonFromShift,
  personFromShiftRemoved,
  deleteShift,
  shiftDeleted,
  editShiftDate,
  shiftDateEdited,
} from 'behavior/shift/actions';

const actions = {
  addPerson,
  editPerson,
  deletePerson,
  getPersonList,
  personAdded,
  personEdited,
  personDeleted,
  personListLoaded,
  getCurrentShiftDuration,
  setShiftDuration,
  getAllShifts,
  addShift,
  getPeoplePerShift,
  setPeoplePerShift,
  peoplePerShiftChanged,
  peoplePerShiftLoaded,
  removePersonFromShift,
  personFromShiftRemoved,
  deleteShift,
  shiftDeleted,
  editShiftDate,
  shiftDateEdited,
};

export default actions;